import request from '@/utils/request'

const brokersApi = {
  // get brokers info
  brokers: '/api/v1/rest/brokers',
  create: '/api/v1/invite-code/create',
  findByEnterStatusAndKeyword: '/api/v1/rest/brokers/search/findByEnterStatusAndKeyword',
  findByEnterStatus: '/api/v1/rest/brokers/search/findByEnterStatus',
  query: '/api/v1/broker/query'
}

/**
 * broker
 * @param parameter { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function brokers(parameter) {
  let url = brokersApi.brokers
  if (parameter) {
    url += '?' + Object.keys(parameter).map(item => `${item}=${parameter[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * add
 * @param parameter { id: '', name: '', nickname: '', tel: '' }
 * @returns {*}
 */
export function addBrokers(parameter) {
  return request({
    url: brokersApi.brokers,
    method: 'post',
    data: parameter
  })
}

/**
 * edit
 * @param parameter { id: '', name: '', nickname: '', tel: '' }
 * @returns {*}
 */
export function editBrokers(parameter) {
  return request({
    url: brokersApi.brokers + `/${parameter.id}`,
    method: 'patch',
    data: parameter
  })
}

/**
 * del
 * @param parameter { id: '' }
 * @returns {*}
 */
export function delBrokers(parameter) {
  return request({
    url: brokersApi.brokers + `/${parameter.id}`,
    method: 'delete'
  })
}
/**
 * search
 * @param parameter { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findBrokers(parameter) {
  let url = brokersApi.findByEnterStatusAndKeyword
  if (parameter) {
    url += '?' + Object.keys(parameter).map(item => `${item}=${parameter[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param parameter { enterStatus, keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function queryBrokers(parameter) {
  let url = brokersApi.query
  if (parameter) {
    url += '?' + Object.keys(parameter).map(item => `${item}=${parameter[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
