<template>
  <div>
    <broker :enterStatus="enterStatus"></broker>
  </div>
</template>

<script>
import Broker from './modules/broker'
export default {
  components: {
    Broker
  },
  data() {
    return {
      enterStatus: true
    }
  }
}
</script>
