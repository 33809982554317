<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="姓名">
                <a-input v-model="queryParam.brokerName" placeholder="请输入姓名" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="昵称">
                <a-input v-model="queryParam.brokerUsername" placeholder="请输入昵称" />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-modal
        title="刪除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleDeleteCancel"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="brokerData"
        :alert="true"
        :totalCount="totalCount"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="inviteType" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleCheck(record)">查看</a>
            <!-- <a-divider type="vertical" />
            <a @click="handleEdit(record)">编辑</a> -->
          </template>
        </span>
      </s-table>

      <create-form
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleCancel"
        @ok="handleOk"
      />
    </a-card>
    <!-- broker info -->
    <a-drawer
      title="经纪人详情"
      placement="right"
      width="500px"
      class="myDescription"
      :closable="false"
      :visible="brokerVisible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
    >
      <a-card :bordered="false">
        <a-descriptions title="基本信息">
          <a-descriptions-item label="姓名" span="3">{{
            brokerInfo ? brokerInfo.name : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="性别" span="3">{{
            brokerInfo ? (brokerInfo.sex === 0 ? '女' : '男') : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="年龄" span="3">{{
            brokerInfo ? (brokerInfo.age ? brokerInfo.age: '暂无信息' ) : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="手机号" span="3">{{
            brokerInfo ? brokerInfo.phone : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="入驻时间" span="3">{{
            brokerInfo ? brokerInfo.enterTime : '暂无信息'
          }}</a-descriptions-item>
          <!-- <a-descriptions-item label="地址" span="3">{{
            brokerInfo ? `${brokerInfo.wx.country ? `${brokerInfo.wx.country}-` : ''}${ brokerInfo.wx.province ? `${brokerInfo.wx.province}-` : ''}${brokerInfo.wx.city ? brokerInfo.wx.city : '-'}` : '暂无信息'
          }}</a-descriptions-item> -->
        </a-descriptions>
        <!-- <a-divider style="margin-bottom: 32px" />
        <a-descriptions title="实名信息">
          <a-descriptions-item label="真实姓名" span="3">{{ '暂无信息' }}</a-descriptions-item>
          <a-descriptions-item label="身份证号" span="3">{{ '暂无信息' }}</a-descriptions-item>
          <a-descriptions-item label="出生日期" span="3">{{ '暂无信息' }}</a-descriptions-item>
          <a-descriptions-item label="籍贯" span="3">{{ '暂无信息' }}</a-descriptions-item>
          <a-descriptions-item label="民族" span="3">{{ '暂无信息' }}</a-descriptions-item>
          <a-descriptions-item label="学历" span="3">{{ '暂无信息' }}</a-descriptions-item>
        </a-descriptions>
        <a-divider style="margin-bottom: 32px" />
        <a-descriptions title="佣金信息"> </a-descriptions>
        <a-table :columns="commissionColumns" :data-source="commission" :pagination="false" bordered> </a-table> -->
      </a-card>
    </a-drawer>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis } from '@/components'
import { addBrokers, editBrokers, delBrokers } from '@/api/broker'
import { findBrokerByDeveloper } from '@/api/statistics'
import CreateForm from './CreateForm'

// a-table commission
const commissionColumns = [
  {
    title: '佣金总额',
    dataIndex: 'money'
  },
  {
    title: '已结佣金',
    dataIndex: 'clsd'
  },
  {
    title: '待结佣金',
    dataIndex: 'wait'
  },
  {
    title: '可结佣金',
    dataIndex: 'yes'
  },
  {
    title: '审批中',
    dataIndex: 'approve'
  }
]
const commission = [
  {
    money: '￥300,000.00',
    clsd: '￥200,000.00',
    wait: '￥100,000.00',
    yes: '￥50,000.00',
    approve: '￥50,000.00'
  }
]

const statusMap = {
  1: {
    status: 'success',
    text: '邀请码'
  },
  2: {
    status: 'success',
    text: '邀请链接'
  },
  3: {
    status: 'success',
    text: '二维码邀请'
  }
}

export default {
  name: 'BrokerList',
  components: {
    STable,
    Ellipsis,
    CreateForm
  },
  props: {
    enterStatus: {
      type: Boolean,
      defautl: () => true
    }
  },
  data() {
    // commission
    this.commissionColumns = commissionColumns
    this.commission = commission
    return {
      totalCount: 0,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      brokerInfo: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 批量删除 modal
      del: {
        ModalText: '您要删除这些项目吗',
        visible: false,
        confirmLoading: false
      },
      // 查询参数
      queryParam: {
        brokerName: '',
        brokerUsername: ''
      },
      search: {
        visible: false
      },
      // 加载数据方法 必须为 Promise 对象
      brokerData: parameter => {
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          developerId: this.developerId,
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr
        }
        if (this.search.visible) {
          requestParameters.brokerName = this.queryParam.brokerName
          requestParameters.brokerUsername = this.queryParam.brokerUsername
        }
        return findBrokerByDeveloper(requestParameters)
          .then(res => {
            const result = this.resFormat(res)
            this.totalCount = result.totalCount
            return result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // broker info
      brokerVisible: false,
      pStyle: {
        fontSize: '16px',
        color: 'rgba(0,0,0,0.85)',
        lineHeight: '24px',
        display: 'block',
        marginBottom: '16px'
      },
      pStyle2: {
        marginBottom: '24px'
      }
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type] ? statusMap[type].text : '其他'
    },
    statusTypeFilter(type) {
      return statusMap[type] ? statusMap[type].status : 1
    }
  },
  created() {},
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    developerId() {
      return this.$store.state.user.info.id
    },
    columns() {
      const columns = [
        {
          title: '经纪人昵称',
          dataIndex: 'name'
        },
        {
          title: '电话',
          dataIndex: 'phone'
        },
        {
          title: '入驻时间',
          dataIndex: 'enterTime',
          sorter: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
      return columns
    }
  },
  methods: {
    handleAdd() {
      this.mdl = null
      this.visible = true
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这些项目吗'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          this.selectedRows.forEach((item, index) => {
            const requestParameters = {
              id: item.id
            }
            delBrokers(requestParameters)
              .then(res => {
                // 刷新表格
                this.$refs.table.refresh()
                this.$message.success('删除成功', 2)
                this.del.visible = false
                this.confirmLoading = false
                this.selectedRows.splice(0, this.selectedRows.length)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      this.del.visible = false
    },
    handleEdit(record) {
      this.visible = true
      this.mdl = { ...record }
    },
    afterVisibleChange(val) {
      console.log('visible', val)
    },
    handleCheck(record) {
      console.log(record, 'record');
      this.brokerVisible = true
      this.brokerInfo = { ...record }
    },
    onClose() {
      this.brokerVisible = false
    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          const requestParameters = { ...values }
          if (this.mdl !== null) {
            requestParameters.id = this.mdl.id
            // 新增/修改
            editBrokers(requestParameters)
              .then(res => {
                setTimeout(() => {
                  const msg = '编辑成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                }, 1000)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          } else {
            // 新增/修改
            addBrokers(requestParameters)
              .then(res => {
                setTimeout(() => {
                  const msg = '新建成功'
                  this.visible = false
                  this.confirmLoading = false
                  // 重置表单数据
                  form.resetFields()
                  // 刷新表格
                  this.$refs.table.refresh()
                  this.$message.success(msg)
                }, 1000)
              })
              .catch(() => {
                this.confirmLoading = false
              })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    findByKeyword() {
      this.search.visible = true
      this.$refs.table.refresh()
    },
    tableReset() {
      this.search.visible = false
      this.queryParam = {
        keyword: ''
      }
      this.$refs.table.refresh()
    }
  }
}
</script>
